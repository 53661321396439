<style scoped>
  .form-item {
    position: relative;
  }

  .form-item .icon-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .form-item .role-list {
    padding-bottom: 30px;
  }

  .form-item .role-box {
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    overflow: hidden;
    font-size: 0.8rem;
  }

  .role-total-list ul {
    overflow: hidden;
  }

  .role-total-list ul li {
    float: left;
    width: 18%;
    margin-right: 2.5%;
    text-align: center;
    margin-bottom: 5px;
    color: #444;
  }

  .role-total-list ul li:nth-of-type(5n) {
    margin-right: 0;
  }

  .role-total-list ul li a {
    display: block;
    position: relative;
  }

  .role-total-list ul li a::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid #dfdfdf;
  }

  .role-total-list ul li img {
    display: block;
    width: 100%;
  }

  .role-total-list ul li span {
    display: block;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
    .inputstyll{
     background: rgba(255,255,255,0.5);
     border: initial;
    }
</style>
<template>
  <div>
    <div class="addCon">
      <header-title :titleCon="titleCon" @titleLeftBtn="titleFnReturn" @titleFnBtnLf="saveChapter"></header-title>
      <div class="content book-edit-form form-block" style="padding-bottom: 30px;">
        <div class="form-item">
          <label>章节名称</label>
          <input class="inputstyll" type="text" v-model="ugcChapter.chapterTitle" placeholder="请填写章节名称" maxlength="15" />
        </div>
        <div class="form-item">
          <label>初始背景</label>
          <input class="inputstyll" type="text" readonly v-model="ugcChapterContent.ugcBackpicVo.backpicTitle" @click="addBg" placeholder="请添加初始背景" />
          <img class="icon-right" src="../../assets/image/icon_right.png" height="18">
        </div>
        <div class="form-item">
          <label>初始音乐</label>
          <input class="inputstyll" type="text" readonly v-model="ugcChapterContent.ugcMusicVo.musicTitle" @click="addBgm" placeholder="请添加初始音乐" />
          <img class="icon-right" src="../../assets/image/icon_right.png" height="18">
        </div>
        <div class="form-item form-item-type">
          <label>
            <span>初始章节表现形式</span>
            <span v-if="type === 'add'" class="fr gt" @click="changeShowType">{{showTypeInfo[showType].showName}}</span>
            <span v-else class="fr gt">{{showTypeInfo[showType].showName}}</span>
          </label>
          <ul class="look-type">
            <li><img :src="showTypeInfo[showType].showImg" width="160"></li>
          </ul>
        </div>
        <div class="form-item">
          <label>登场角色 <span class="fr">*在创作过程中还可以继续添加角色</span></label>
          <div class="role-total-list">
            <ul>
              <li>
                <a @click="goRoleManage"><img src="../../assets/image/addroleadd.png" /></a>
                <span>添加</span>
              </li>
              <li v-for="(item, index) in totalRoleList" :key="index">
                <a><img :src="item.ugcCharacterpicPath + '?x-oss-process=image/crop,x_170,y_20,w_400,h_520'" /></a>
                <span>{{item.characterName}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="form-btn btn-bottom-box">
          <button class="btn-bottom" @click="saveChapter">完成</button>
        </div>
      </div>
    </div>
    <dialog-bottom-type v-if="dialogBottomTypeShow" :showTypeInfo="showTypeInfo" :showTypeIndex="showTypeIndex"
      @sltTypeFn="sltTypeFn" @sureType="sureType" @dialogShowBottom="dialogShowBottom"
      @dialogCloseBottom="dialogCloseBottom">
    </dialog-bottom-type>
    <!--<look v-if="isLook" :titlecon="ugcChapter.chapterTitle" :chapterInfo="chapterInfo" :chapterInfoEdit="chapterInfoEdit" @closeLook="closeLook" @touchmove.prevent style="position: fixed;top: 0;left: 0;right: 0;background: #fff;"></look>-->
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
import dialogBottomType from '../../components/dialog/dialog-bottom-type.vue'
// import look from '../../components/look/look.vue'
import qs from 'qs'
// var saveFlag = true
export default {
  components: {
    headerTitle,
    dialogBottomType
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      isReClick: true, // 重复点击
      type: 'add', // 编辑或新增
      titleCon: '新建章节',
      showType: 0, // 章节表现形式0、1、2
      showTypeIndex: 0,
      chapterType: 0, // 普通1，子章节2
      dialogBottomTypeShow: false, // 章节形式弹框
      showTypeInfo: [ // 章节形式信息
        {
          showImg: require('../../assets/image/type01.gif'),
          showName: '半身像模式'
        },
        {
          showImg: require('../../assets/image/type02.gif'),
          showName: '胸像模式'
        },
        {
          showImg: require('../../assets/image/type03.gif'),
          showName: '网聊模式'
        }
      ],
      chapterId: '',
      ugcChapter: {}, // 章节信息
      ugcChapterContent: { // 背景，音乐，角色
        ugcBackpicVo: {
          // id: '',
          // backpicTitle: '',
          // backpicPath: ''
        },
        ugcMusicVo: {
          // id: '',
          // musicTitle: '',
          // musicPath: ''
        },
        chapterDialogs: []
      },
      totalRoleList: [], // 章节所有角色
      // chapterInfo: {
      //   chapterId: '',
      //   chapterName: '',
      //   showType: 0,
      //   chapterType: 0,
      //   chapterBg: {},
      //   chapterBgm: {},
      //   roleList: []
      // },
      chapterInfoEdit: '',
      making: null
    }
  },
  computed: {},
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    // console.log(this.$route.query.ugcBookDefault)
    // 新人从模板进来
    this.making = this.$route.query.making
    // console.log(this.making)
    if (this.making === '1') {
      // 初始背景名称
      this.ugcChapterContent.ugcBackpicVo.backpicTitle = this.$route.query.ugcBookDefault.ugcBackpic.backpicTitle
      // 背景id
      this.ugcChapterContent.ugcBackpicVo.id = this.$route.query.ugcBookDefault.ugcBackpic.id
      // 初始音乐名称
      this.ugcChapterContent.ugcMusicVo.musicTitle = this.$route.query.ugcBookDefault.ugcMusic.musicTitle
      // 背景音乐id
      this.ugcChapterContent.ugcMusicVo.id = this.$route.query.ugcBookDefault.ugcMusic.id
      // 获取到四个人物
      this.axios({
        method: 'get',
        url: '/v1/character/default',
        params: {
          chapterId: this.$route.query.chapterId,
          ugcBookId: this.$route.query.bookId
        }
      }).then((res) => {
        // console.log(res)
        // if (res.data.head.flag === 0) {
        // console.log(res.data.content)
        var newarr = res.data.head.desc
        this.axios({
          method: 'POST',
          url: '/v1/saveCharacters',
          data: qs.stringify({
            chapterId: this.$route.query.chapterId,
            characterIds: newarr,
            ugcBookId: this.$route.query.bookId
          })
        }).then((res) => {
          // console.log(res)
          this.zhangjiexinxi()
        })
        // }
      })
    }
    this.chapterType = this.$route.query.chapterType
    // 章节信息
    this.zhangjiexinxi()
  },
  mounted () {
    // window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    // console.log(this.$store.state.chapterSonEdit)
  },
  methods: {
    zhangjiexinxi () {
      // 章节信息
      this.axios({
        method: 'get',
        url: '/v1/chapter/toEditPage',
        params: {
          ugcBookId: this.$route.query.bookId,
          chapterId: this.$route.query.chapterId,
          chapterType: this.$route.query.chapterType,
          type: this.$route.query.type
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.head.flag === 0) {
          // console.log(res.data.content)
          // console.log(this.$store.state.chapterInfo)
          this.totalRoleList = res.data.content.chapterCharacters || []
          this.ugcChapter = res.data.content.ugcChapter || {}
          this.type = res.data.content.type
          if (res.data.content.type === 'add') {
            this.titleCon = '新建章节'
            this.chapterId = res.data.content.chapterId
            // 章节模式
            if (this.$store.state.chapterInfo.showType >= 0) {
              this.showType = this.$store.state.chapterInfo.showType
            } else {
              this.showType = 0
            }
          } else {
            this.titleCon = '章节设置'
            this.chapterId = res.data.content.ugcChapter.id
            this.showType = res.data.content.ugcChapter.showType
          }
          // 加载时章节有内容
          if (res.data.content.ugcChapterContent !== null) {
            this.ugcChapterContent = res.data.content.ugcChapterContent || {}
            this.ugcChapterContent.ugcBackpicVo = res.data.content.ugcChapterContent.ugcBackpicVo || {}
            this.ugcChapterContent.ugcMusicVo = res.data.content.ugcChapterContent.ugcMusicVo || {}
          }
          // 背景图编辑过
          if (this.$store.state.chapterInfo.chapterBg.backpicTitle || this.$store.state.chapterInfo.chapterBg
            .backpicTitle === '') {
            this.ugcChapterContent.ugcBackpicVo.backpicTitle = this.$store.state.chapterInfo.chapterBg.backpicTitle
            this.ugcChapterContent.ugcBackpicVo.id = this.$store.state.chapterInfo.chapterBg.id
            this.ugcChapterContent.ugcBackpicVo.backpicPath = this.$store.state.chapterInfo.chapterBg.backpicPath
          }
          // 背景音乐编辑过
          if (this.$store.state.chapterInfo.chapterBgm.musicTitle || this.$store.state.chapterInfo.chapterBgm
            .musicTitle === '') {
            this.ugcChapterContent.ugcMusicVo.musicTitle = this.$store.state.chapterInfo.chapterBgm.musicTitle
            this.ugcChapterContent.ugcMusicVo.id = this.$store.state.chapterInfo.chapterBgm.id
            this.ugcChapterContent.ugcMusicVo.musicPath = this.$store.state.chapterInfo.chapterBgm.musicPath
          }
          // 章节名称编辑过
          if (this.$store.state.chapterInfo.chapterName) {
            this.ugcChapter.chapterTitle = this.$store.state.chapterInfo.chapterName
          }
          if (this.$store.state.chapterSonEdit.length > 0) {
            var that = this
            this.$store.state.chapterSonEdit.forEach(function (ele, idx) {
              that.ugcChapterContent.chapterDialogs[that.$store.state.chapterSonEdit[idx].subIndex]
                .ugcSubChapter = that.$store.state.chapterSonEdit[idx].ugcSubChapter
            })
          }
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },

    // beforeunloadFn (e) {
    //   console.log('刷新或关闭')
    // },
    // 返回
    titleFnReturn () {
      this.clearChapterInfo()
      this.$router.push({
        path: '/chapterManage',
        query: {
          bookId: this.$route.query.bookId
        }
      })
    },
    // 去背景库
    addBg () {
      this.saveChapterInfo()
      this.$router.push({
        path: '/chapterBg',
        query: {
          bookId: this.$route.query.bookId,
          chapterId: this.$route.query.chapterId,
          chapterType: this.$route.query.chapterType,
          type: this.$route.query.type
        }
      })
    },
    // 去音乐库
    addBgm () {
      this.saveChapterInfo()
      this.$router.push({
        path: '/chapterBgm',
        query: {
          bookId: this.$route.query.bookId,
          chapterId: this.$route.query.chapterId,
          chapterType: this.$route.query.chapterType,
          type: this.$route.query.type
        }
      })
    },
    // 去角色管理
    goRoleManage () {
      this.saveChapterInfo()
      this.$router.push({
        path: '/roleManage',
        query: {
          bookId: this.$route.query.bookId,
          chapterId: this.chapterId,
          chapterType: this.$route.query.chapterType,
          chapterSet: 0,
          type: this.$route.query.type
        }
      })
    },
    // 本章添加角色
    addRole (index) {
      this.ugcChapterContent.chapterDialogs.push({
        ugcCharacter: this.totalRoleList[index],
        dialog: '',
        ugcSubChapter: null
      })
      this.axios({
        method: 'get',
        url: '/v1/character/updTime',
        params: {
          chapterId: this.ugcChapter.id,
          characterId: this.totalRoleList[index].id
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {} else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 选择章节形式
    changeShowType () {
      this.dialogBottomTypeShow = true
      this.showTypeIndex = this.showType
    },
    // 点击第几个形式
    sltTypeFn (index) {
      this.showTypeIndex = index
      this.dialogBottomTypeShow = true
    },
    // 确定形象
    sureType () {
      this.showType = this.showTypeIndex
      this.dialogBottomTypeShow = false
    },
    // 底部弹框弹出
    dialogShowBottom () {
      this.dialogBottomTypeShow = true
    },
    // 底部弹框关闭
    dialogCloseBottom () {
      this.dialogBottomTypeShow = false
    },
    // 前端保存章节编辑信息
    saveChapterInfo () {
      this.$store.state.chapterInfo.chapterName = this.ugcChapter.chapterTitle
      this.$store.state.chapterInfo.chapterBg.backpicTitle = this.ugcChapterContent.ugcBackpicVo.backpicTitle
      this.$store.state.chapterInfo.chapterBg.id = this.ugcChapterContent.ugcBackpicVo.id
      this.$store.state.chapterInfo.chapterBg.backpicPath = this.ugcChapterContent.ugcBackpicVo.backpicPath
      this.$store.state.chapterInfo.chapterBgm.musicTitle = this.ugcChapterContent.ugcMusicVo.musicTitle
      this.$store.state.chapterInfo.chapterBgm.id = this.ugcChapterContent.ugcMusicVo.id
      this.$store.state.chapterInfo.chapterBgm.musicPath = this.ugcChapterContent.ugcMusicVo.musicPath
      this.$store.state.chapterInfo.roleList = this.ugcChapterContent.chapterDialogs
      this.$store.state.chapterInfo.showType = this.showType
      // this.$store.state.roleEdit = this.ugcChapterContent.chapterDialogs
      if (this.$store.state.sltCptBgActive.firstTypeId === '') {
        localStorage.setItem('sltCptBgActive', JSON.stringify(this.ugcChapterContent.ugcBackpicVo.sltCptBgActive))
        localStorage.setItem('sltBgId', this.ugcChapterContent.ugcBackpicVo.id)
      }
      if (this.$store.state.sltCptBgmActive.firstTypeId === '') {
        localStorage.setItem('sltCptBgmActive', JSON.stringify(this.ugcChapterContent.ugcMusicVo.sltCptBgmActive))
        localStorage.setItem('sltBgmId', JSON.stringify(this.ugcChapterContent.ugcMusicVo.id))
      }
    },
    // 清空编辑信息
    clearChapterInfo () {
      // this.$store.state.roleEdit = []
      this.$store.state.chapterInfo = { // 添加章节信息
        chapterName: '',
        chapterBg: {},
        chapterBgm: {},
        roleList: []
      }
      this.$store.state.chapterSonEdit = []
      localStorage.setItem('sltCptBgActive', '')
      localStorage.setItem('sltCptBgmActive', '')
      this.$store.state.sltCptBgActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
      this.$store.state.sltCptBgmActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
    },
    // 保存章节编辑给后台
    saveChapter () {
      if (this.ugcChapter.chapterTitle === '' || this.ugcChapter.chapterTitle === undefined) {
        this.$toast('请输入章节名称')
      } else {
        var chapterId = ''
        if (this.type === 'add') {
          chapterId = this.chapterId
        } else {
          chapterId = this.ugcChapter.id
        }
        // if (this.isReClick) {
        if (this.$store.state.sltCptBgActive.firstTypeId === '') {
          localStorage.setItem('sltCptBgActive', JSON.stringify(this.ugcChapterContent.ugcBackpicVo.sltCptBgActive))
        }
        if (this.$store.state.sltCptBgmActive.firstTypeId === '') {
          localStorage.setItem('sltCptBgmActive', JSON.stringify(this.ugcChapterContent.ugcMusicVo.sltCptBgmActive))
        }
        if (this.isReClick) {
          this.axios({
            method: 'post',
            url: '/v1/chapter/save',
            data: qs.stringify({
              // authorId: sessionStorage.getItem('authorId'),
              ugcBookId: this.$route.query.bookId,
              type: this.type,
              chapterId: chapterId,
              chapterTitle: this.ugcChapter.chapterTitle,
              backpicId: this.ugcChapterContent.ugcBackpicVo.id,
              musicId: this.ugcChapterContent.ugcMusicVo.id,
              showType: this.showType,
              chapterType: parseInt(this.$route.query.chapterType),
              chapterDialogs: JSON.stringify(this.ugcChapterContent.chapterDialogs),
              sltCptBgActive: localStorage.getItem('sltCptBgActive'),
              sltCptBgmActive: localStorage.getItem('sltCptBgmActive')
            })
          }).then((res) => {
            // console.log(res)
            if (res.data.head.flag === 0) {
              // this.clearChapterInfo()
              if (this.$route.query.chapterType === '1') {
                this.$router.push({
                  path: '/chapterCon',
                  query: {
                    bookId: this.$route.query.bookId,
                    chapterId: chapterId,
                    chapterType: this.$route.query.chapterType,
                    type: 'edit'
                    // bookId: this.$route.query.bookId,
                    // chapterType: this.$route.query.chapterType
                  }
                })
                // this.$router.push({
                //   path: '/chapterManage',
                //   query: {
                //     bookId: this.$route.query.bookId,
                //     chapterType: this.$route.query.chapterType
                //   }
                // })
              } else {
                this.$router.push({
                  path: '/chapterCon',
                  query: {
                    bookId: this.$route.query.bookId,
                    chapterId: chapterId,
                    chapterType: this.$route.query.chapterType,
                    type: 'edit'
                    // bookId: this.$route.query.bookId
                  }
                })
                // this.$router.push({
                //   path: '/chapterManage',
                //   query: {
                //     bookId: this.$route.query.bookId
                //   }
                // })
              }
              this.isReClick = false
            } else {
              this.$toast(res.data.head.desc)
            }
          })
        } else {
          this.$toast('重复点击了')
        }
      }
    }
  }
}
</script>
